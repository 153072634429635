import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import axios from 'axios';
import Searcher from './components/Searcher';
import { BOND_DASHBOARD_API_URL } from '../../config/constants';
import { formatCurrency } from '../../utils/formatCurrency';
import BondsTable from './components/BondsTable';
import HomeChart from './components/Chart';

function Home() {
  const [salesData, setSalesData] = useState<any>({});
  const [bondData, setBondData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const domain = window.location.hostname;

  const showFees = window.location.hostname.includes('aitech'); //Only showing fees for AI Tech
  const feeSplit = 30;

  useEffect(() => {
    setLoading(true);

    const urlBase = BOND_DASHBOARD_API_URL;

    const bondsUrl = 'https://api-v2.apeswap.finance/stats';

    let url = `${urlBase}/collector/salesInfo`;

    if (domain.includes('polygon-bonds-dashboard')) {
      url = `${urlBase}/collector/salesInfo/137`;
    } else if (domain.includes('aitech')) {
      url = `${urlBase}/collector/salesInfo/null/ai-tech`;
    }

    if (window.location.href.includes('excludeFlash=true')) {
      url += '?excludeFlash=true';
    }

    axios
      .get(url)
      .then(response => {
        setSalesData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });

    axios
      .get(bondsUrl)
      .then(response => {
        var bonds: any = [];

        for (const key in response.data) {
          bonds = bonds.concat(response.data[key].bonds);
        }

        setBondData(bonds);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Searcher />
      {loading && (
        <div className="row mt-5">
          <div className="col-12 text-center mb-3">
            <i className="fa-solid fa-circle-notch fa-spin fa-3x"></i>
          </div>
        </div>
      )}
      {/* Figures */}
      {!loading && (
        <>
          <div className="row mt-3">
            <div className="col-md-6 col-sm-12">
              {salesData.monthlySalesData && (
                <HomeChart
                  chartData={salesData.monthlySalesData}
                  title="Monthly Sales"
                  type="months"
                />
              )}
            </div>
            {salesData.last60DaysData && (
              <div className="col-md-6 col-sm-12">
                <HomeChart
                  chartData={salesData.last60DaysData.sums}
                  labels={salesData.last60DaysData.dates}
                  title="Last 60 days sales"
                  type="days"
                />
              </div>
            )}
          </div>
          {/*<div>{formatCurrency(salesData.RemainingValue30Days)}</div>*/}
          <div className="row">
            <div className="col-12">
              <div className="row align-items-start">
                {salesData.figures?.length > 0 &&
                  salesData.figures?.map((x: any) => (
                    <div className="col-md-4 col-sm-12">
                      <div className="detail-box mb-3">
                        <div className="row">
                          <div className="col">
                            <h5 className="mb-2 text-uppercase">
                              <a href={`/period/${x.Label?.replace(' ', '_')}`}>
                                {x.Label}
                              </a>
                            </h5>
                          </div>
                          {showFees === true && (
                            <div className="col">
                              <h5 className="mb-2 text-uppercase text-end heading-light heading-small">
                                Est. Fees:&nbsp;
                                {formatCurrency(
                                  (((x.DollarValue / 100) * 5) / 100) *
                                    feeSplit,
                                  false,
                                )}
                              </h5>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <h4 className="mb-0">
                              {formatCurrency(x.DollarValue, false)}
                            </h4>
                            <span className="text-uppercase mb-0">
                              Dollar Value
                            </span>
                          </div>
                          <div className="col-4">
                            <h4 className="text-uppercase mb-0">
                              {x.TotalSales}
                            </h4>
                            <span className="text-uppercase mb-0">Total</span>
                          </div>
                          <div className="col-4">
                            <h4 className="text-uppercase mb-0">
                              {formatCurrency(x.FirstTimeBuyerAmount, false)}
                            </h4>
                            <span className="text-uppercase mb-0">
                              New Buyers
                            </span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-6"></div>
                          <div className="col-6"></div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-6"></div>
                        </div>
                        <div className="row">
                          {x.Top3BondSales.map((y: any) => (
                            <div className="col-4">
                              <img
                                src={`/${y.chainId}.png`}
                                className={styles.chainImg}
                              />
                              <h6 className="text-uppercase mb-0">
                                <a href={`/bond/${y.contractAddress}`}>
                                  {y.payoutToken.slice(0, 6)}
                                </a>
                              </h6>
                              <span className="text-uppercase mb-0">
                                {formatCurrency(y.totalDollarValue, false)}
                              </span>
                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="mb-3">
            {/* Bonds Table */}
            <BondsTable salesData={salesData} bondData={bondData} />

            {/*/!* Sales Table *!/*/}

            {/*<SalesTable salesData={salesData} />*/}
          </div>
        </>
      )}
    </>
  );
}

export default Home;
