import React, { useEffect, useState } from 'react';
import styles from './Bond.module.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { BOND_DASHBOARD_API_URL } from '../../config/constants';
import { Checkbox, Flex } from 'theme-ui';
import { formatUTCDate } from '../../utils/formatDate';
import { formatCurrency } from '../../utils/formatCurrency';
import { Knob } from 'primereact/knob';
import { ITEMS_PER_PAGE } from '../Home/types';
import { getPaginationNumbers } from '../../utils/getPaginationNumbers';
import * as domain from 'domain';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  scales: {
    y: {
      grid: {
        display: true,
        color: 'rgba(250, 250, 250, 0.1)',
        drawOnChartArea: true,
      },
      beginAtZero: true,
    },
    x: {
      grid: {
        display: true,
        color: 'rgba(250, 250, 250, 0.1)',
        drawOnChartArea: true,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  pointRadius: 0,
  responsive: true,
  maintainAspectRatio: false,
};

interface ChartDataset {
  label: string;
  data: number[];
  fill: boolean;
  borderColor: string;
  backgroundColor: string;
  tension: number;
}

// Define an interface for the chart data
interface ChartData {
  labels: string[];
  datasets: ChartDataset[];
}

interface AddressProps {}

interface SaleItem {
  contractAddress: string;
  billNftId: string;
  payoutTokenPrice: number;
  createdAddressOwner: string;
  createdAt: number;
  lp: string;
  payoutToken: string;
  vestingTime: number;
  payout: number;
  deposit: number;
  dollarValue: number;
  chainId: string;
}

interface chartDataItem {
  count: number;
  date: string;
}

function stripDate(input: number): string {
  const date = new Date(input * 1000);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11
  const day = date.getUTCDate();

  return `${year}-${month}-${day}`;
}

function Bond() {
  const { address } = useParams();
  const showFees = window.location.hostname.includes('aitech'); //Only showing fees for AI Tech
  const feeSplit = 30;

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const ITEMS_PER_PAGE = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const [salesAmountChartData, setSalesAmountChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: 'Sales Amount (USD)',
        data: [],
        fill: true,
        borderColor: 'rgb(192,75,75)',
        backgroundColor: 'rgba(192,75,75,0.2)',
        tension: 0.4,
      },
    ],
  });
  const [salesCountChartData, setSalesCountChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: 'Sales Count',
        data: [],
        fill: true,
        borderColor: '#6560C5',
        backgroundColor: 'rgba(101, 96, 197, 0.9)',
        tension: 0.4,
      },
    ],
  });

  useEffect(() => {
    const domain = window.location.hostname;

    let url = `${BOND_DASHBOARD_API_URL}/collector/salesInfo/bond/${address}/0`;

    if (domain.includes('aitech')) {
      url = `${BOND_DASHBOARD_API_URL}/collector/salesInfo/bond/${address}/ai-tech`;
    }

    setLoading(true);
    axios
      .get(url)
      .then(response => {
        setData(response.data);
        const reversedData = [...response.data.Sales].reverse();
        const countsByDate: { salesNum: number; salesAmount: number }[] =
          reversedData.reduce((acc, item) => {
            const dateStr = stripDate(item.createdAt);

            if (!acc[dateStr]) {
              acc[dateStr] = { salesNum: 0, salesAmount: 0 };
            }

            const dollarValue = parseFloat(item.dollarValue) || 0;
            acc[dateStr].salesNum += 1;
            acc[dateStr].salesAmount += Math.round(dollarValue);

            return acc;
          }, {});

        const salesByDate = Object.values(countsByDate);
        const labels = Object.keys(countsByDate);

        setSalesCountChartData({
          labels: labels,
          datasets: [
            {
              ...salesCountChartData.datasets[0],
              data: salesByDate.map(sale => sale?.salesNum),
            },
          ],
        });
        setSalesAmountChartData({
          labels: labels,
          datasets: [
            {
              ...salesAmountChartData.datasets[0],
              data: salesByDate.map(sale => sale?.salesAmount),
            },
          ],
        });
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, [address]);

  const filteredSales = data?.Sales || [];
  const [filter, setFilter] = useState<boolean>(true);
  const filterLowValueBonds = (bonds: any[]) => {
    return bonds.filter(bond => bond.dollarValue > 10);
  };
  const bondsToRender = filter
    ? filterLowValueBonds(filteredSales)
    : filteredSales;
  const totalPages = Math.ceil(bondsToRender.length / ITEMS_PER_PAGE);

  const handlePageChange = (
    newPage: number,
    setter: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    setter(newPage);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedSales = bondsToRender.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  return (
    <>
      <div className="row mb-0">
        <div className="col text-start">
          <h3 className="mb-1">{data?.Sales?.[0]?.lp}</h3>
          <span>{address}</span>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-7 col-sm-12">
          <div className="detail-box">
            <h5 className="mb-3 text-uppercase">Daily Sales</h5>
            <div className={styles.chartContainer}>
              <Line options={options} data={salesCountChartData} />
            </div>
          </div>
        </div>
        <div className="col-md-5 col-sm-12">
          <div className="row">
            <div className="col-12">
              <div className="detail-box">
                <div className="row mb-3">
                  <div className="col-4">
                    <h4 className="mb-0">{data?.Sales?.length}</h4>
                    <span className="text-uppercase mb-0">Total Purchases</span>
                  </div>
                  <div className="col-4">
                    <h4 className="mb-0">
                      {formatCurrency(data?.totalDollarValue)}
                    </h4>
                    <span className="text-uppercase mb-0">Total $ Value</span>
                  </div>
                  <div className="col-4">
                    <h4 className="mb-0">
                      {formatCurrency(
                        data?.totalDollarValue / data?.Sales?.length,
                      )}
                    </h4>
                    <span className="text-uppercase mb-0">AVG Sale Value</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h4 className="mb-0">
                      {formatCurrency(data?.valueRemaining, false)}
                    </h4>
                    <span className="text-uppercase mb-0">Value Remaining</span>
                  </div>
                  <div className="col-4">
                    <h4 className="mb-0">
                      {data?.Sales?.length > 0 &&
                        formatUTCDate(
                          new Date(
                            data.Sales[data.Sales.length - 1].createdAt * 1000,
                          ),
                          true,
                        )}
                    </h4>
                    <span className="text-uppercase mb-0">First Sale</span>
                  </div>
                  <div className="col-4">
                    <h4 className="mb-0">
                      {data?.Sales?.length > 0 &&
                        formatUTCDate(
                          new Date(data.Sales[0].createdAt * 1000),
                          true,
                        )}
                    </h4>
                    <span className="text-uppercase mb-0">
                      Most Recent Sale
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="mb-sm-4 col-md-6 col-sm-12 mb-sm-3">
              <div className="detail-box text-center">
                {data?.percentageSold && (
                  <Knob
                    value={data?.percentageSold}
                    size={176}
                    valueTemplate={`${data?.percentageSold
                      .toFixed()
                      .toString()}%`}
                    valueColor="#6560C5"
                    rangeColor="rgba(250, 250, 250, 0.05)"
                    textColor="#f6f6f6"
                  />
                )}

                <h5 className="mb-0 text-uppercase">Percentage Sold</h5>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="detail-box text-center">
                {data.ROE && (
                  <Knob
                    value={data.ROE}
                    size={176}
                    valueTemplate={`${Number(data.ROE).toFixed().toString()}%`}
                    valueColor="#72A98F"
                    rangeColor="rgba(250, 250, 250, 0.05)"
                    textColor="#f6f6f6"
                  />
                )}

                <h5 className="mb-0 text-uppercase">ROE</h5>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="col-6">*/}
        {/*  <h5 className="mt-3 mb-3">Daily Sales Value (USD)</h5>*/}
        {/*  <div className={styles.chartContainer}>*/}
        {/*    <Line options={options} data={salesAmountChartData} />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      {data?.percentageSold < 99 && (
        <div className="row mb-3">
          <div className="col-md-12">
            <a
              className="btn btn-primary btn-lg d-block w-100"
              target="_blank"
              href={`https://ape.bond/bonds?bondAddress=${address}&bondChain=${data?.Sales?.[0]?.chainId}`}
            >
              BUY THIS BOND
            </a>
          </div>
        </div>
      )}
      <div className="detail-box">
        <div className="row">
          <div className="col-6">
            <h5 className="mb-3 text-uppercase">All Bonds</h5>
          </div>
          <div className="col-6 text-end">
            <Flex sx={{ width: '100%', justifyContent: 'flex-end', mb: '5px' }}>
              <Flex onClick={() => setFilter(!filter)}>
                <Checkbox checked={filter} />
                Filter low value bonds
              </Flex>
            </Flex>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>Bond</th>
                <th>Purchase Date</th>
                <th>Buyer</th>
                <th>Amount Paid</th>
                <th>Token Amount</th>

                <th>Payout Token Price</th>
                <th>Payout Value</th>
                <th>Discount</th>
                {showFees === true && <th>Estimated Fees</th>}
              </tr>
            </thead>
            <tbody>
              {paginatedSales.map((item: SaleItem, index: number) => (
                <tr key={index}>
                  <td>{item.lp}</td>
                  <td>{formatUTCDate(new Date(item.createdAt * 1000))}</td>
                  <td>
                    <a href={`/address/${item.createdAddressOwner}`}>
                      {item.createdAddressOwner
                        ? item.createdAddressOwner.substring(0, 5)
                        : ''}
                      ...
                      {item.createdAddressOwner
                        ? item.createdAddressOwner.substring(
                            item.createdAddressOwner.length - 5,
                          )
                        : ''}
                    </a>
                  </td>
                  <td className="highlighted-column">
                    {formatCurrency(item.dollarValue)}
                  </td>
                  <td>
                    {item.payout.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>

                  <td>
                    {formatCurrency(
                      item.payoutTokenPrice,
                      true,
                      item.payoutTokenPrice > 1 ? 2 : 5,
                    )}
                  </td>
                  <td>{formatCurrency(item.payout * item.payoutTokenPrice)}</td>
                  <td>
                    {(
                      ((item.payoutTokenPrice -
                        item.dollarValue / item.payout) /
                        item.payoutTokenPrice) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                  {showFees === true && (
                    <td>
                      {formatCurrency(
                        (((item.dollarValue / 100) * 5) / 100) * feeSplit,
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-end mb-0">
          <nav aria-label="Bonds Page navigation">
            <ul className="pagination">
              {getPaginationNumbers(totalPages, currentPage).map(page => (
                <li
                  className={`page-item ${
                    currentPage === page ? 'active' : ''
                  }`}
                  key={page}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page, setCurrentPage)}
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Bond;
