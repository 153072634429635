import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './views/Home/Home';
import Address from './views/Address/Address';
import './App.scss';
import Bond from './views/Bond/Bond';
import Period from './views/Period/Period';

const App: React.FC = () => {
  return (
    <div className="container text-center mt-3">
      <a href="/">
        <svg
          width="130"
          height="28"
          viewBox="0 0 200 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mb-3"
        >
          <path
            d="M5.33462 12.1563C5.2475 12.445 5.12657 12.9014 5.12657 12.9014C5.12452 12.9066 5.12349 12.9108 5.12247 12.9159C4.79347 14.1413 4.61821 15.3925 4.61821 16.6365C4.61821 16.7255 4.61821 16.8114 4.62026 16.8983C4.63871 18.4186 4.45218 19.9296 4.11703 21.4106L3.96739 22.017C3.19564 21.7128 2.40543 21.1342 1.65007 20.1687C-0.443818 17.4903 0.254146 13.1923 2.83692 12.1801C3.8485 11.7848 4.68688 11.8862 5.33565 12.1563H5.33462Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M38.5326 20.1676C37.7834 21.1249 36.9994 21.7034 36.2327 22.0077L36.0862 21.4074C35.751 19.9265 35.5645 18.4165 35.5819 16.8962C35.5819 16.8093 35.5819 16.7234 35.5819 16.6344C35.5819 15.3852 35.4067 14.1299 35.0746 12.9004L34.8491 12.1542C35.4969 11.8841 36.3352 11.7837 37.3448 12.178C39.9275 13.1902 40.6255 17.4872 38.5316 20.1666L38.5326 20.1676Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M33.915 20.9635C33.625 19.6833 33.4641 18.3782 33.4794 17.0639C33.4794 16.9894 33.4794 16.9149 33.4794 16.8383C33.4794 15.7589 33.3277 14.6743 33.0408 13.6104L32.7856 12.7659C32.6288 12.3012 32.4474 11.8406 32.2424 11.3894C32.0927 11.0603 31.9318 10.7353 31.7586 10.4166C30.2171 7.56952 27.7604 5.14367 24.8958 3.80862C24.4736 3.61199 23.5358 1.43658 23.5358 1.43658L21.3271 2.77577C21.2389 2.76542 21.1508 2.75611 21.0616 2.74783C20.8987 2.73334 20.7367 2.72195 20.5717 2.71471L19.8317 0.916016C17.1086 1.85365 15.5025 3.70409 14.9778 3.96696C12.2536 5.32995 9.92496 7.67923 8.44192 10.4166C8.26769 10.7364 8.1078 11.0603 7.95816 11.3894C7.75318 11.8406 7.57177 12.3012 7.41394 12.7659C7.38729 12.7503 7.15976 13.6093 7.15976 13.6093C7.15771 13.6135 7.15668 13.6176 7.15668 13.6217C6.87278 14.6805 6.7211 15.762 6.7211 16.8372C6.7211 16.9138 6.7211 16.9883 6.7211 17.0629C6.7375 18.3772 6.57556 19.6833 6.28551 20.9635L6.04261 21.9529C6.03236 21.9994 6.02211 22.046 6.01186 22.0926C5.86632 22.7632 5.7915 23.4514 5.7915 24.1552C5.7915 28.9665 9.30592 33.1041 14.3413 34.9246C15.4882 35.3406 16.715 35.6356 17.9961 35.7908C18.6336 35.8684 19.2844 35.9098 19.9476 35.916C19.9988 35.916 20.0511 35.916 20.1023 35.916C28.0044 35.916 34.4101 30.6493 34.4101 24.1541C34.4101 23.4504 34.3352 22.7611 34.1897 22.0915C34.1795 22.045 34.1692 21.9984 34.1579 21.9529L33.915 20.9614V20.9635Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M85.4847 13.4789C86.7064 14.0378 87.6473 14.8295 88.3073 15.8561C88.9673 16.8828 89.2974 18.0988 89.2974 19.5032C89.2974 20.9076 88.9673 22.1019 88.3073 23.1368C87.6473 24.1728 86.7064 24.9655 85.4847 25.514C84.263 26.0636 82.823 26.3378 81.1637 26.3378H77.391V31.5517H73.0566V12.6417H81.1637C82.823 12.6417 84.263 12.9211 85.4847 13.4789ZM83.893 21.9218C84.5705 21.3547 84.9097 20.5485 84.9097 19.5032C84.9097 18.4579 84.5715 17.6258 83.893 17.0577C83.2145 16.4905 82.2245 16.207 80.9228 16.207H77.391V22.7725H80.9228C82.2245 22.7725 83.2145 22.4889 83.893 21.9218Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M107.05 28.0413V31.5538H92.5483V12.6417H106.702V16.1542H96.856V20.2608H105.551V23.6646H96.856V28.0413H107.05Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M127.156 23.4349C127.763 24.2369 128.067 25.2232 128.067 26.3937C128.067 28.0506 127.429 29.3246 126.154 30.2167C124.879 31.1078 123.019 31.5538 120.576 31.5538H110.89V12.6417H120.041C122.323 12.6417 124.077 13.0836 125.298 13.9653C126.52 14.8481 127.131 16.0455 127.131 17.5586C127.131 18.4776 126.913 19.2972 126.476 20.0175C126.038 20.7379 125.427 21.305 124.643 21.72C125.713 22.0626 126.552 22.6349 127.159 23.4359L127.156 23.4349ZM115.197 15.9379V20.3953H119.505C120.575 20.3953 121.386 20.2059 121.94 19.8282C122.492 19.4504 122.769 18.8916 122.769 18.1537C122.769 17.4158 122.492 16.861 121.94 16.4916C121.386 16.1221 120.576 15.9379 119.505 15.9379H115.197ZM122.835 27.6905C123.415 27.3127 123.706 26.7269 123.706 25.9342C123.706 24.3673 122.555 23.5839 120.254 23.5839H115.197V28.2576H120.254C121.395 28.2576 122.256 28.0682 122.835 27.6905Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M136.318 30.6079C134.757 29.7613 133.535 28.595 132.653 27.1088C131.769 25.6227 131.329 23.9523 131.329 22.0967C131.329 20.2411 131.77 18.5707 132.653 17.0846C133.535 15.5984 134.757 14.4331 136.318 13.5855C137.879 12.739 139.631 12.3157 141.576 12.3157C143.52 12.3157 145.267 12.739 146.82 13.5855C148.372 14.4321 149.594 15.5984 150.485 17.0846C151.377 18.5707 151.823 20.2411 151.823 22.0967C151.823 23.9523 151.377 25.6227 150.485 27.1088C149.592 28.595 148.372 29.7613 146.82 30.6079C145.268 31.4545 143.52 31.8778 141.576 31.8778C139.631 31.8778 137.879 31.4545 136.318 30.6079ZM144.571 27.379C145.463 26.8656 146.163 26.1495 146.671 25.2315C147.18 24.3125 147.434 23.2682 147.434 22.0977C147.434 20.9273 147.18 19.882 146.671 18.964C146.163 18.045 145.463 17.3299 144.571 16.8165C143.68 16.3032 142.681 16.0466 141.575 16.0466C140.469 16.0466 139.469 16.3032 138.578 16.8165C137.686 17.3299 136.986 18.046 136.478 18.964C135.969 19.883 135.715 20.9273 135.715 22.0977C135.715 23.2682 135.969 24.3135 136.478 25.2315C136.986 26.1505 137.686 26.8656 138.578 27.379C139.469 27.8923 140.469 28.1489 141.575 28.1489C142.681 28.1489 143.68 27.8923 144.571 27.379Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M172.25 12.6417V31.5538H168.692L159.354 20.0714V31.5538H155.073V12.6417H158.658L167.969 24.1241V12.6417H172.25Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M176.584 12.6417H185.092C187.126 12.6417 188.922 13.0339 190.483 13.8173C192.044 14.6008 193.257 15.704 194.122 17.127C194.987 18.55 195.419 20.2069 195.419 22.0977C195.419 23.9885 194.987 25.6465 194.122 27.0685C193.257 28.4915 192.043 29.5947 190.483 30.3781C188.922 31.1616 187.126 31.5538 185.092 31.5538H176.584V12.6417ZM184.879 27.9606C186.752 27.9606 188.246 27.4338 189.361 26.3802C190.475 25.3267 191.034 23.8995 191.034 22.0977C191.034 20.2959 190.476 18.8688 189.361 17.8152C188.246 16.7617 186.752 16.2349 184.879 16.2349H180.919V27.9606H184.879Z"
            fill="#FAFAFA"
          ></path>
          <path
            d="M52.8731 31.5538H48.4312L56.779 12.6417H61.0601L69.4346 31.5538H64.8861L58.8924 16.9376L52.8721 31.5538H52.8731Z"
            fill="#FAFAFA"
          ></path>
        </svg>
      </a>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/address/:address" element={<Address />} />
          <Route path="/bond/:address" element={<Bond />} />
          <Route path="/period/:period" element={<Period />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
